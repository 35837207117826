<template>
  <div>
    <table class="min-w-full divide-y divide-slate-900">
        <div class="mt-2 flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle px-4 md:px-6 lg:px-8">
                  <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                    <table class="min-w-full divide-y divide-slate-900">
                    <thead class="bg-slate-800">
                        <tr>
                          <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-300 sm:pl-6"></th>
                          <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Nazwa</th>
                          <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Opis</th>
                          <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Data przesłania</th>
                          <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Waga</th>
                          <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300"></th>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-slate-700 bg-slate-500">
                        <tr v-for="(file, index) in this.fileList" :key="index" :class="[index % 2 ? 'bg-slate-700' : 'bg-slate-600']">
                          <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-slate-200 sm:pl-6"><DocumentTextIcon class="h-6 w-6 text-slate-200" aria-hidden="true"></DocumentTextIcon></td>
                          <td v-tooltip="`${file.displayName}`" class="whitespace-nowrap px-2 py-2 text-sm text-slate-200 cursor-default">{{ file.displayName.length > 35 ? file.displayName.slice(0,35) + '...' : file.displayName}}</td>
                          <td v-tooltip="`${file.description}`" class="whitespace-nowrap px-2 py-2 text-sm text-slate-200 cursor-default">{{ file.description.length > 60 ? file.description.slice(0,70) + '...' : file.description}}</td>
                          <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-300">{{ translateUnix(file.uploadDate) }}</td>
                          <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-300">{{ (file.size / 1024 / 1024).toFixed(2) }}mb</td>
                          <td class="whitespace-nowrap py-3 pl-3 pr-4 text-sm text-slate-300"><button @click.prevent="openUrl(file.url)" type="button" class="transition ease-in-out duration-200 rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-blue-100 hover:text-blue-600">Otwórz</button></td>
                        </tr>
                    </tbody>
                    </table>
                  </div>
                  <div v-if="this.fileList.length === 0">
                      <EmptyState></EmptyState>
                  </div>
                </div>
            </div>

        </div>
    </table>
  </div>   
</template>

<script>
import { DownloadIcon, ArrowNarrowRightIcon,DocumentReportIcon, DocumentTextIcon } from '@heroicons/vue/outline'
import { db } from "../firebase/gfbconf"
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import EmptyState from '../components/EmptyState.vue';
import moment from 'moment'

export default {
  data() {
    return {
      fileList: []
    }
  },
  components: {
    DownloadIcon,
    DocumentTextIcon,
    DocumentReportIcon,
    ArrowNarrowRightIcon,
    LoadingSpinnerHub,
    EmptyState
  },
  methods: {
    translateUnix(unix){
        moment.locale('pl')
        let translated = moment.unix(unix).format("lll");
        return translated
    },
    openUrl(url){
        window.open(url, '_blank');
    },
  },
  async created(){
    this.fileList = this.$store.state.userData.files
    this.fileList.sort((a,b)=>
    {
        if(a.uploadDate > b.uploadDate) return 1;
        if(a.uploadDate < b.uploadDate) return -1;
        return 0;
    })
    this.fileList.reverse();
  },
  beforeUnmount() {}
}
</script>

<style scoped>

</style>