<template>
        <div class="wrap h-3 flex items-center">
                <div :class="['h-2 w-2 mr-1 rounded-full transition-all duration-300', balls[0] ? 'bg-slate-300' :  'bg-slate-200', !this.balls[2] && this.balls[1] ? 'mb-0.5' : 'mt-0.5']"></div>
                <div :class="['h-2 w-2 mr-1 rounded-full transition-all duration-300', balls[1] ? 'bg-slate-300' :  'bg-slate-200', !this.balls[0] && this.balls[2] ? 'mb-0.5' : 'mt-0.5']"></div>
                <div :class="['h-2 w-2 rounded-full transition-all duration-300', balls[2] ? 'bg-slate-300' : 'bg-slate-200', !this.balls[1] && this.balls[0] ? 'mb-0.5' : 'mt-0.5']"></div>
        </div>
</template>
<script>
export default {
  name: "Loader",
  props: ['header','subheader'],
  data(){
    return{
        currentType: 0,
      balls: {
        0: false,
        1: false,
        2: false,
      },
    }
  },
  created()
    {
      this.changeBall()
    },
  methods:{
    changeBall(){
      if (this.currentType === 0) {
        this.balls[0] = true
        this.balls[1] = false
        this.balls[2] = false
        this.currentType = 1
        setTimeout(() => {
            this.changeBall()
        }, 210);
        return 0
      }
      if (this.currentType === 1) {
        this.balls[0] = false
        this.balls[1] = true
        this.balls[2] = false
        this.currentType = 2
        setTimeout(() => {
            this.changeBall()
        }, 210);
        return 0
      }
      if (this.currentType === 2) {
        this.balls[0] = false
        this.balls[1] = false
        this.balls[2] = true
        this.currentType = 0
        setTimeout(() => {
            this.changeBall()
        }, 210);
        return 0
      }
    }
  }
};
</script>