<template>
  <div>
    <p class="mb-4 truncate text-sm text-slate-200">Skontaktuj się z obsługą WB-TANK.</p>
    <div class="text-slate-200">
        <div Class="divide-y-2 divide-slate-700">
    <div class="mt-8">
      <div class="divide-y-2 divide-slate-700">
        <div class="lg:grid lg:grid-cols-1 lg:gap-8">
          <!-- <h2 class="text-2xl font-bold sm:text-3xl sm:tracking-tight text-slate-500">Dane kontaktowe</h2> -->
          <div class="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-8 sm:gap-y-12 lg:col-span-2 lg:mt-0">
            <div>
              <h3 class="text-lg font-medium leading-6 text-slate-400">Firma</h3>
              <dl class="mt-2 text-base">
                <div>
                  <dt class="sr-only">Nazwa firmy</dt>
                  <dd>WBTANK Sp. z o.o.</dd>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">Ulica</dt>
                  <dd>Udorpie 93, ul. Chełmińska 3</dd>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">Kod pocztowy oraz miasto</dt>
                  <dd>77-100 Bytów</dd>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">NIP</dt>
                  <dd>NIP 842-176-12-90</dd>
                </div>
              </dl>
            </div>
            <div>
              <h3 class="text-lg font-medium leading-6 text-slate-400">Obsługa</h3>
              <dl class="mt-2 text-base">
                <div>
                  <dt class="sr-only">E-mail</dt>
                  <dd>rafal@wbtank.pl</dd>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">Numer telefonu</dt>
                  <dd>+48 59 822 33 44</dd>
                  <dd class="text-slate-400">lub</dd>
                  <dd>+48 601 98 35 35</dd>
                </div>
              </dl>
            </div>
            <!-- <div>
              <h3 class="text-lg font-medium leading-6 text-slate-400">Odwiedź naszą stronę</h3>
              <dl class="mt-2 text-base">
                <div>
                  <dt class="sr-only">E-mail</dt>
                  <a href="http://wbtank.smarthost.pl/" target="_blank" class="transition ease-in-out duration-200 hover:text-blue-400">wbtank.smarthost.pl</a>
                </div>
              </dl>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="mt-16">
        <div class="mt-16 mb-4">
            <div class="py-2">
                <h3 class="text-xl font-medium leading-6 text-slate-50">Nasze stacje</h3>
                <p class="mt-2 max-w-2xl text-sm text-slate-100">Sprawdź nasze lokalizacje.</p>
            </div>
        </div>
      <div class="divide-y-2 divide-slate-700">
        <div class="lg:grid lg:grid-cols-3 lg:gap-8">
          <div class="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:col-span-2 lg:mt-0">
            <div>
              <div class="flex">
                <h3 class="text-lg font-medium leading-6 text-slate-400">Shell</h3>
                <img class="h-6 w-auto ml-2" src="https://firebasestorage.googleapis.com/v0/b/wbtank-65cca.appspot.com/o/assets%2Fshell-4-logo-svg-vector.svg?alt=media&token=8d724f57-81a0-466f-9a16-93ec2b0933a0" alt="WB-TANK Sp. z o.o." />
              </div>
              <dl class="mt-2 text-base">
                <div class="mt-1">
                  <dt class="sr-only">Ulica</dt>
                  <dd>Udorpie 93</dd>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">Kod pocztowy oraz miasto</dt>
                  <dd>77-100 Bytów</dd>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">Państwo</dt>
                  <dd>Polska</dd>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">Mapa</dt>
                  <a href="https://www.google.com/maps/dir//Shell+Udorpie+93+77-100/@54.153794,17.4930064,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x4702062723cb0421:0x79c69fc39c2c10d6" target="_blank" class="transition ease-in-out duration-200 text-blue-500 hover:text-blue-400">Zobacz na mapie</a>
                </div>
              </dl>
            </div>
            <div>
              <div class="flex">
                <h3 class="text-lg font-medium leading-6 text-slate-400">Shell</h3>
                <img class="h-6 w-auto ml-2" src="https://firebasestorage.googleapis.com/v0/b/wbtank-65cca.appspot.com/o/assets%2Fshell-4-logo-svg-vector.svg?alt=media&token=8d724f57-81a0-466f-9a16-93ec2b0933a0" alt="WB-TANK Sp. z o.o." />
              </div>
              <dl class="mt-2 text-base">
                <div class="mt-1">
                  <dt class="sr-only">Ulica</dt>
                  <dd>XYZ</dd>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">Kod pocztowy oraz miasto</dt>
                  <dd>00-000 XYZ</dd>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">Państwo</dt>
                  <dd>Polska</dd>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">Mapa</dt>
                  <a href="https://www.google.com/maps/dir//Shell+Udorpie+93+77-100/@54.153794,17.4930064,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x4702062723cb0421:0x79c69fc39c2c10d6" target="_blank" class="transition ease-in-out duration-200 text-blue-500 hover:text-blue-400">Zobacz na mapie</a>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
    </div>
  </div>
</template>

<script>
export default {
    data()
        {
            return {
            }
        },
    components: {
    },
    methods: {
    },
    created(){
    }
}
</script>

<style scoped>

</style>